<nav class="navbar navbar-expand-lg navbar-light navbar-light-four sticky-top">
    <a class="navbar-brand" routerLink="/home-five"><img src="assets/img/kfs.png" width="300" alt="Kandel Financial Services"></a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
<!--        <ul class="navbar-nav">-->
<!--            <li class="nav-item"><a class="nav-link" href="#home"  style="color: black">Home</a></li>-->

<!--            <li class="nav-item"><a class="nav-link" href="#about"  style="color: black">About Us</a></li>-->

<!--            <li class="nav-item"><a class="nav-link" href="#services"  style="color: black">Services</a></li>-->

<!--            <li class="nav-item"><a class="nav-link" href="#contact"  style="color: black">Contact</a></li>-->
<!--        </ul>-->
    </div>
    <div class="navbar-login">
        <a target="_blank" class="navbar-login" href="https://welcome.dashly.com/property-lookup"><i class='bx bx-link-external'></i>Get Started</a>
    </div>

</nav>

<div>
    <div class="five-banner-area">
        <div class="banner-shape">
            <img src="assets/img/home-five/banner1.png" alt="Shape">
            <img src="assets/img/Untitled.png" alt="Shape">
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="banner-content">
                        <span>Welcome to KFS</span>
                        <p style="font-size: 50px; font-weight: lighter"><b>Independent</b> Mortgage Advisors</p>
                        <p>Making Home Buying Process Simple </p>
                        <div class="banner-form">
                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <div class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Mortgage Calculator</div>
                                </li>
                            </ul>
                            <app-calculator></app-calculator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="four-step-area five-step-area pt-100 pb-70">-->
<!--    <div class="container-fluid">-->
<!--        <div class="one-section-title three-section-title">-->
<!--            <span class="sub-title"># STEPS & PROCESS</span>-->
<!--            <h2>Whole Of Market Mortgage Broker</h2>-->
<!--            <p>Get the best mortgage deal for you</p>-->
<!--        </div>-->
<!--        <div class="four-step-slider owl-theme owl-carousel" style="background: #6E2965;">-->
<!--            <div class="step-item step-one">-->
<!--                <img src="assets/img/banks/Lloyds.png" />-->
<!--            </div>-->
<!--            <div class="step-item step-one">-->
<!--                <img src="assets/img/banks/Halifax.png" />-->
<!--            </div>-->
<!--            <div class="step-item step-one">-->
<!--                <img src="assets/img/banks/HSBC.png" />-->
<!--            </div>-->
<!--            <div class="step-item step-one ">-->
<!--                <img src="assets/img/banks/Accord.png" />-->
<!--            </div>-->
<!--            <div class="step-item step-one">-->
<!--                <img src="assets/img/banks/Nationwide.png" />-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<section class="three-loan-area five-loan-area ptb-100">
    <div class="container">
        <div class="one-section-title three-section-title">
            <h2>Mortgage Types</h2>
            <p>Providing independent mortgage advice to client’s needs.</p>
        </div>

        <div class="five-loan-slider owl-theme owl-carousel">
            <div class="loan-item" (click)="showModal(1)">
                <h3>First Time Mortgage </h3>
                <p>{{mortgages.firstTime}}...</p>
                <a>READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

            <div class="loan-item" (click)="showModal(2)">
                <h3>Remortgage</h3>
                <p>{{mortgages.remortgage}}...</p>
                <a>READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

            <div class="loan-item"  (click)="showModal(3)">
                <h3>Moving Home</h3>
                <p>{{mortgages.movingHome}}...</p>
                <a>READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

            <div class="loan-item" (click)="showModal(4)">
                <h3>Buy to Let</h3>
                <p>{{mortgages.btl}}...</p>
                <a >READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            <div class="loan-item" (click)="showModal(5)">
                <h3>Self-employed Mortgage</h3>
                <p>{{mortgages.selfEmployed}}...</p>
                <a>READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            <div class="loan-item" (click)="showModal(6)">
                <h3>Bad Credit Mortgage</h3>
                <p>{{mortgages.badCredit}}...</p>
                <a>READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>
            <div class="loan-item" (click)="showModal(7)">
                <h3>Buy to let mortgages for limited companies</h3>
                <p>{{mortgages.ltd}}...</p>
                <a>READ MORE <i class='bx bx-right-arrow-alt'></i></a>
            </div>

        </div>
    </div>
</section>

<div id="about" class="three-about-area five-about-area pb-70">
    <div class="about-shape">
        <img src="assets/img/home-five/about-shape2.png" alt="About">
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/img1.jpg" width="780" alt="About">
                    <img src="assets/img/home-five/about-shape.png" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title three-section-title">
                        <p style="font-weight: bold; font-size: 30px">Providing independent mortgage advice to client’s needs.</p>
                    </div>

                    <div class="about-mission">
                        <div class="tab-content" id="pills-tabContent-two">
                            <div class="tab-pane fade show active" id="pills-home-two" role="tabpanel" aria-labelledby="pills-home-tab-two">
                                <p>We provide independent mortgage advice unlike many high street banks. This means that we advise on the entire range of products available in the mortgage market. This will allow us to offer best mortgage available that suits your needs.</p>
                                <p>At Kandel Financial Services we would like to think “out of the box” and make sure your family, home and income are protected, we will work closely with you to make sure that right cover is in place. We have access to variety of protection providers allowing us to get a suitable cover for your needs.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="one-faq-area four-faq-area five-faq-area pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How much can I borrow??</a>
                            <p>Generally-speaking, you may be able to borrow between four and a half times your salary. So, if you earn £50,000 a year, that means you could borrow between £200,000 and £225000.</p>
                        </li>
                        <li>
                            <a>What does a decision in principle mean?</a>
                            <p>A decision in principle shows what a lender could be prepared to lend you. It’s also known as a mortgage in principle or an agreement in principle. It’ll give you an idea of what you can afford – handy for when you start house hunting.</p>
                        </li>
                        <li>
                            <a>What does loan to value mean with a mortgage?</a>
                            <p> The loan to value – often shortened to LTV – is the size of the mortgage compared to how much your property is worth. It’s usually expressed as a percentage figure.
                                For example, if a mortgage is offered at 95% LTV, you’ll need to find a deposit of 5%. The lower the LTV, the lower the mortgage interest rate tends to be.
                            </p>
                        </li>
                        <li>
                            <a>How can I pay off my mortgage more quickly?</a>
                            <p>If you want to pay off your mortgage more quickly, you could look at making overpayments. Paying even an extra £50 per month will reduce the overall interest you pay and can reduce the term of your mortgage.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 pr-0">
                <div class="faq-img-two">
                    <img src="assets/img/mortgage_1.jpg" width="815" alt="FAQ">
                </div>
            </div>
        </div>
    </div>
</div>

<!--<section class="two-lover-area five-lover-area pt-100 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="one-section-title three-section-title">-->
<!--            <h2>Our work speaks for itself</h2>-->
<!--        </div>-->

<!--        <div class="row">-->
<!--            <div class="col-lg-6">-->
<!--                <div class="lover-item">-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <div class="lover-content">-->
<!--                                <h3>Hari K</h3>-->
<!--                                <p>Bibas got me an exceptional mortgage deal that I never would have gotten approaching lenders myself. Highly professional and excellent results, can't wait to get my hands on my keys!! </p>-->
<!--                                <ul>-->
<!--                                    <li><i class='bx bxs-star checked'></i></li>-->
<!--                                    <li><i class='bx bxs-star checked'></i></li>-->
<!--                                    <li><i class='bx bxs-star checked'></i></li>-->
<!--                                    <li><i class='bx bxs-star checked'></i></li>-->
<!--                                    <li><i class='bx bxs-star checked'></i></li>-->
<!--                                </ul>-->

<!--                                <div class="lover-icon">-->
<!--                                    <i class='bx bxs-quote-left'></i>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<div class="four-map-area">
    <div class="container-fluid p-0">
        <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2235.280448673742!2d-3.114998983452012!3d55.92717598060043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4887b91daa925a31%3A0x7af9162a2d5bda31!2s26%20The%20Wisp%2C%20Edinburgh%20EH16%204SH!5e0!3m2!1sen!2suk!4v1654119378080!5m2!1sen!2suk"></iframe>
    </div>
</div>

<footer id="contact" class="one-footer-area four-footer-area five-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item ">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/kfs.png" alt="Logo"></a>
                        <p>26 The Wisp, Edinburgh, EH16 4SQ</p>
                        <p></p>
                        <ul>
                            <li><a href="https://www.facebook.com/profile.php?id=100075868367695" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/kandel_financial/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://g.co/kgs/RMCFrw" target="_blank"><i class='bx bxl-google'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-9">
                <div class="footer-item">
                    <div class="footer-logo">
                        <p><b>YOUR HOME MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON YOUR MORTGAGE</b></p>
                        <p>
                            We charge a fee of up to £999 payable upon full application to the lender. Our typical fee is £499.
                            Kandel Financial Services is a trading name of MacNab Financial Services Limited who is an Appointed Representative of PRIMIS Mortgage Network. PRIMIS Mortgage Network is a trading style of Personal Touch Financial Services Ltd which is authorised and regulated by the Financial Conduct Authority. Registered Office: 2 Vicarage Road, Verwood, Dorset, BH31 6DR. Registered in England & Wales. Company Registration Number: 12081343.

                        </p>
                        <p>
                            The guidance and/or advice contained in this website is subject to UK regulatory regime and is therefore restricted to consumers based in the UK
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
</footer>

