<div class="one-header-top two-header-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3">
                <div class="header-social">
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-partner">
                    <p>We recognize this is a difficult time for many people. <a href="#">Become a Partner</a></p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="header-call">
                    <ul>
                        <li>
                            <span>Call Us:</span>
                            <a href="tel:+4234543123678">+4 234-543 123 678</a>
                        </li>
                        <li>
                            <span>Mail Us:</span>
                            <a href="mailto:info@levi.com">info@levi.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-expand-lg navbar-light navbar-light-two sticky-top">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>

            <li class="nav-item"><a class="nav-link" href="#about">About Us</a></li>

            <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>

            <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>

            <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>

            <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
        </ul>
    </div>

    <div class="side-nav">
        <a class="side-nav-left" routerLink="/">Sign In</a>
        <a class="side-nav-right cd-signup" routerLink="/"><i class='bx bx-link-external'></i>Sign Up</a>
    </div>
</nav>

<div class="three-banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-three/banner-shape2.png" alt="Banner">
        <img src="assets/img/home-three/banner-shape3.png" alt="Banner">
    </div>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                <img src="assets/img/home-three/banner-icon.png" alt="Banner">
                                Welcome To Levi
                            </span>
                            <h1>Buy And Get Better Home</h1>
                            <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tr inciddunt ut labore et dolore adipiscing elit sed do eiusr adipiscing</p>
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" routerLink="/">Mortgage Expert <i class='bx bx-right-arrow-alt'></i></a>
                                </li>
                                <li>
                                    <a class="banner-play popup-youtube" href="https://www.youtube.com/watch?v=aqz-KE-bpKQ"><i class='bx bx-play'></i></a>
                                    <span>How It’s Work</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="banner-img">
                            <img src="assets/img/home-three/banner1.png" alt="Banner">
                            <img src="assets/img/home-three/banner-shape1.png" alt="Banner">
                            <img src="assets/img/home-three/banner-shape1.png" alt="Banner">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="two-banner-wrap three-banner-wrap">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 p-0">
                <div class="banner-time-left">
                    <ul>
                        <li>
                            <i class='bx bx-money'></i>
                            <span>What is price?</span>
                            <p>Mortgage Rates</p>
                        </li>
                        <li>
                            <i class='bx bx-calculator'></i>
                            <span>Get calculated?</span>
                            <a routerLink="/">Calculators</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="banner-time-right">
                    <ul>
                        <li>
                            <i class='bx bx-dollar-circle'></i>
                            <span>What’s paying?</span>
                            <p>Payment By Credit Card</p>
                        </li>
                        <li>
                            <i class='bx bxs-contact'></i>
                            <span>Faq Or ask?</span>
                            <span>Get Quick Information</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="three-about-area pb-70">
    <div class="about-shape">
        <img src="assets/img/home-three/about4.png" alt="About">
    </div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/home-three/about1.jpg" alt="About">
                    <img src="assets/img/home-three/about2.png" alt="About">
                    <img src="assets/img/home-three/about3.png" alt="About">
                    <div class="about-inner">
                        <h3>20+ Years</h3>
                        <p>Lorem ipsum dolor sit amet, ctur re et dolore magnam aliquam quaerat luptatem.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title two-section-title">
                        <span class="sub-title">ABOUT US</span>
                        <h2>We Are Fully Dedicated To Support You</h2>
                    </div>

                    <div class="about-mission">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                                    <i class='bx bx-bullseye'></i>
                                    OUR MISSION
                                </a>
                            </li>

                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                                    <i class='bx bx-show-alt'></i>
                                    OUR VISION
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit.</p>
                                <ul>
                                    <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                </ul>
                                <a class="cmn-banner-btn" routerLink="/">Learn More<i class='bx bx-right-arrow-alt'></i></a>
                            </div>

                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <p>Our plan dolor sit amet conseetur diisci velit sed quiLoresum dolor sit ame consectetur adipisicing elit.</p>
                                <ul>
                                    <li><i class='bx bx-chevrons-right'></i> Respect for all people</li>
                                    <li><i class='bx bx-chevrons-right'></i> Excellence in everything we do</li>
                                    <li><i class='bx bx-chevrons-right'></i> Truthfulness in our business</li>
                                </ul>
                                <a class="cmn-banner-btn" routerLink="/">Learn More<i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="services" class="one-among-area two-among-area three-among-area pt-100">
    <div class="container">
        <div class="one-section-title two-section-title">
            <span class="sub-title">WHAT'S YOU EXPECT</span>
            <h2>We Have Offer For You</h2>
            <p>Choose started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua  </p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among1.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bxs-badge-dollar'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Delivering A Great Customer</h3>
                        <p>Gain home equity by paying sit amet seeturlit sed dolor se equity.</p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among2.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-bulb'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Refinancing Your Home</h3>
                        <p>Your home equity by paying sit amet seeturlit sed dolor se equity</p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="among-item">
                    <div class="among-top">
                        <img src="assets/img/home-two/among3.png" alt="Among">
                        <div class="among-icon">
                            <i class='bx bx-money'></i>
                        </div>
                    </div>

                    <div class="among-bottom">
                        <h3>Unlock The Today’s Rate</h3>
                        <p>Money rate equity by paying sit amet seeturlit sed dolor se equity.</p>
                        <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="one-quote-area">
        <div class="container">
            <div class="row m-0">
                <div class="col-lg-6 p-0">
                    <div class="quote-content">
                        <h3>Get Mortgage Quote</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                        <a routerLink="/"><i class='bx bxs-quote-alt-right'></i> Get A Quote</a>
                    </div>
                </div>

                <div class="col-lg-6 p-0">
                    <div class="quote-img">
                        <img src="assets/img/home-one/quote.jpg" alt="Quote">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="three-process-area pb-70">
    <div class="counter-shape">
        <img src="assets/img/home-three/process6.png" alt="Process">
        <img src="assets/img/home-three/process7.png" alt="Process">
    </div>

    <div class="container-fluid">
        <div class="one-section-title two-section-title">
            <span class="sub-title">OUR STEP AND PROCESS</span>
            <h2>Go Beyond With Levi</h2>
        </div>
        
        <div class="row align-items-center">
            <div class="col-sm-6 col-lg-4">
                <div class="process-item">
                    <div class="process-span">
                        <span>04</span>
                    </div>
                    <h3>Appraisal</h3>
                    <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                </div>
                <div class="process-item">
                    <div class="process-span">
                        <span>05</span>
                    </div>
                    <h3>Upfront Underwriting</h3>
                    <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                </div>
                <div class="process-item">
                    <div class="process-span">
                        <span>06</span>
                    </div>
                    <h3>Congratulations!</h3>
                    <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="process-img">
                    <img src="assets/img/home-three/process1.jpg" alt="Process">
                    <img src="assets/img/home-three/process2.png" alt="Process">
                    <img src="assets/img/home-three/process3.png" alt="Process">
                    <img src="assets/img/home-three/process4.png" alt="Process">
                    <img src="assets/img/home-three/process4.png" alt="Process">
                    <img src="assets/img/home-three/process4.png" alt="Process">
                    <img src="assets/img/home-three/process4.png" alt="Process">
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="process-item process-two">
                    <div class="process-span">
                        <span>01</span>
                    </div>
                    <h3>Conversation</h3>
                    <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                </div>

                <div class="process-item process-two">
                    <div class="process-span">
                        <span>02</span>
                    </div>
                    <h3>Application</h3>
                    <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                </div>

                <div class="process-item process-two">
                    <div class="process-span">
                        <span>03</span>
                    </div>
                    <h3>Documents</h3>
                    <p>Learning do amet contur diiscivt suia non nuameius velit modi</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="one-counter-area three-counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>90</h3>
                    <p>YEARS OF EXPERIENCE</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>56</h3>
                    <p>VOLUNTEERS PARTICIPATED</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>98</h3>
                    <p>VETERAN HOMES COMPLETED</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>20</h3>
                    <p>CUSTOMER WORLDWIDE</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="team" class="three-team-area ptb-100">
    <div class="container">
        <div class="one-section-title two-section-title">
            <span class="sub-title">TEAM & LEADERSHIP</span>
            <h2>Find A Loan Officer</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-three/team1.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>

                    <div class="team-bottom">
                        <i class='bx bx-folder'></i>
                        <h3>JAMES TAYLOR</h3>
                        <span>Branch Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-three/team2.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>

                    <div class="team-bottom">
                        <i class='bx bx-folder'></i>
                        <h3>AMANDA SILBER</h3>
                        <span>Account Manager</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-three/team3.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>

                    <div class="team-bottom">
                        <i class='bx bx-folder'></i>
                        <h3>CHRIS GARCIA</h3>
                        <span>Loan Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="team-item">
                    <div class="team-top">
                        <img src="assets/img/home-three/team4.jpg" alt="Team">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="tel:123456789"><i class='bx bx-phone-call'></i></a></li>
                        </ul>
                    </div>
                    <div class="team-bottom">
                        <i class='bx bx-folder'></i>
                        <h3>CHRIS M. SHELTON</h3>
                        <span>Regional Director</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="team-call">
            <p><i class='bx bx-phone-call'></i> Call Now for any help! <a href="tel:(567)234-0543">(567) 234-0543</a></p>
        </div>
    </div>
</div>

<section class="three-loan-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title two-section-title">
            <span class="sub-title">CATEGORIES OF LOAN</span>
            <h2>Find A Own Loan</h2>
        </div>

        <div class="sorting-menu">
            <ul> 
               <li class="filter active" data-filter="all"><i class='bx bx-cart'></i> PURCHASE</li>
               <li class="filter" data-filter=".tyre"><i class='bx bx-user'></i> HOMEBUYER</li>
               <li class="filter" data-filter=".web"><i class='bx bx-dollar' ></i> REFINANCE</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Home Loan</span>
                    <h3>A  Conventional Home Loan</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Homeready</span>
                    <h3>Homeready Fannie Mortgage</h3>
                    <p>Fyigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>High Balance</span>
                    <h3>High Balance Mortgage</h3>
                    <p>Wigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre web">
                <div class="loan-item">
                    <span>Renovation Loans</span>
                    <h3>Renovation Loans For Buyers</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>VA Loans</span>
                    <h3>VA Loans For Military</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Jumbo Loans</span>
                    <h3>When You Need Conforming Limit</h3>
                    <p>Fyigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Purchase Loans</span>
                    <h3>Down Payment Has Stalled </h3>
                    <p>Wigration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Condo Loans</span>
                    <h3>Loans For Condo Support</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>
        </div>
    </div>
</section>

<div class="one-faq-area two-faq-area three-faq-area pt-100 pb-70">
    <div class="faq-shape">
        <img src="assets/img/home-three/faq2.png" alt="FAQ">
        <img src="assets/img/home-three/faq3.png" alt="FAQ">
        <img src="assets/img/home-three/faq4.png" alt="FAQ">
    </div>

    <div class="container">
        <div class="one-section-title two-section-title">
            <span class="sub-title">FREQUENTLY QUESTION</span>
            <h2>Question From Customer</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="faq-img-two">
                    <img src="assets/img/home-three/faq.jpg" alt="FAQ">
                    <img src="assets/img/home-three/process2.png" alt="FAQ">
                    <img src="assets/img/home-three/process2.png" alt="FAQ">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How do you qualify for a loan?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>A Good Lender Will Clearly Explain Mortgage?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>Can You Get A Mortgage Without A Credit Score?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>How Much Home Can You Afford?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="contact" class="one-contact-area three-contact-area ptb-100">
    <div class="container">
        <div class="contact-content">
            <form id="contactForm">
                <div class="one-section-title two-section-title">
                    <span class="sub-title">LET’S CONTACT</span>
                    <h2>Contact With Us</h2>
                </div>

                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-user'></i></label>
                            <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-mail-send'></i></label>
                            <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bx-phone-call'></i></label>
                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label><i class='bx bxs-edit-alt'></i></label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label><i class='bx bx-message-square-detail'></i></label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Message"></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <button type="submit" class="contact-btn btn"><i class='bx bx-plus'></i> Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="three-review-area ptb-100">
    <div class="container">
        <div class="three-review-slider owl-theme owl-carousel">
            <div class="review-item">
                <i class='bx bxs-quote-left'></i>
                <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod te iidunt ut labore et dolore odit aut fugit sed quia dolores eos qui ratione vluptatem sequi nidunt ut labore et dolore magnam aliquam</h2>
                <div class="review-bottom">
                    <img src="assets/img/home-three/review.png" alt="Review">
                    <h3>JAMES TAYLOR</h3>
                    <span>Policy Advisor</span>
                </div>
            </div>

            <div class="review-item">
                <i class='bx bxs-quote-left'></i>
                <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod te iidunt ut labore et dolore odit aut fugit sed quia dolores eos qui ratione vluptatem sequi nidunt ut labore et dolore magnam aliquam</h2>
                <div class="review-bottom">
                    <img src="assets/img/home-three/review.png" alt="Review">
                    <h3>JAMES TAYLOR</h3>
                    <span>Policy Advisor</span>
                </div>
            </div>

            <div class="review-item">
                <i class='bx bxs-quote-left'></i>
                <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod te iidunt ut labore et dolore odit aut fugit sed quia dolores eos qui ratione vluptatem sequi nidunt ut labore et dolore magnam aliquam</h2>
                <div class="review-bottom">
                    <img src="assets/img/home-three/review.png" alt="Review">
                    <h3>JAMES TAYLOR</h3>
                    <span>Policy Advisor</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="three-join-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0">
                <div class="join-item">
                    <h3>Join A Levi Of Change</h3>
                    <a class="cmn-banner-btn" routerLink="/home-three">Career Opportunity <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="join-item join-two">
                    <h3>Join As Community Member</h3>
                    <a class="cmn-banner-btn" routerLink="/home-three">Join Community <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="blog" class="one-blog-area two-blog-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title two-section-title">
            <span class="sub-title">NEWS & BLOG</span>
            <h2>Featured News And Insights Update</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>

                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                09 April 2020
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/">What Means Your Mortgage And 5 Ways To Improve</a></h3>
                        <img src="assets/img/home-one/blog1.png" alt="Blog">
                        <p><a routerLink="/">Zain jonson</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>

                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                10 April 2020
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/">8 Ways To Make Your Home A Smart Home</a></h3>
                        <img src="assets/img/home-one/blog2.png" alt="Blog">
                        <p><a routerLink="/">Movin Jex</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>

                    <div class="blog-bottom">
                        <ul>
                            <li>
                                <i class='bx bx-time-five'></i>
                                11 April 2020
                            </li>
                            <li>
                                <i class='bx bx-comment-detail'></i>
                                0 Comment
                            </li>
                        </ul>
                        <h3><a routerLink="/">5 Ways To build Yourself By Hard Working</a></h3>
                        <img src="assets/img/home-one/blog3.png" alt="Blog">
                        <p><a routerLink="/">Jems</a>, Author</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="one-subscribe-area two-subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-wrap">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <h2>Do you want to get update what’s upcoming?</h2>
                </div>
                <div class="col-lg-6">
                    <form class="newsletter-form" data-bs-toggle="validator">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        <button class="btn subscribe-btn" type="submit">Subscribe now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="one-search-area ptb-100">
    <div class="container">
        <div class="search-wrap">
            <div class="search-content">
                <div class="one-section-title">
                    <span class="sub-title">SERCH YOUR ONE</span>
                    <h2>Find A Loan Officer</h2>
                </div>
                <form>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Real Estate Agents
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                        <label class="form-check-label" for="exampleRadios2">
                            Loan Officer
                        </label>
                    </div>
                    
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="First Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Last Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <select class="form-control">
                                    <option>License State</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>	
                            </div>
                        </div>
                    </div>

                    <div class="search-link">
                        <button type="submit">Search Your Result</button>
                    </div>
                </form>
            </div>

            <div class="search-img">
                <img src="assets/img/home-one/search-man.png" alt="Search">
            </div>
        </div>
    </div>
</div>

<footer class="one-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <p>Levi Financial Services, Inc. 200 Wood Avenue South, Ninth Floor Iselin, NJ 65432</p>
                        <p>Resouece Financial ServicInc. 200 Wood Avenue South</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/">Mortgage Calculator</a></li>
                            <li><a routerLink="/">Mortgage Checklist</a></li>
                            <li><a routerLink="/">Mortgage Glossary</a></li>
                            <li><a routerLink="/">Renting vs Owning</a></li>
                            <li><a routerLink="/">Credit Basics</a></li>
                            <li><a routerLink="/">Disaster Relief Center</a></li>
                            <li><a routerLink="/">Disclosure</a></li>
                            <li><a routerLink="/">Home Disclosure Notice</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>About Us</h3>

                        <ul>
                            <li><a routerLink="/">Down Payment Options</a></li>
                            <li><a routerLink="/">Your Mortgage Payment</a></li>
                            <li><a routerLink="/">Appraisal Basics</a></li>
                            <li><a routerLink="/">Contact</a></li>
                            <li><a routerLink="/">Careers</a></li>
                            <li><a routerLink="/">Privacy Policy</a></li>
                            <li><a routerLink="/">Privacy Choices</a></li>
                            <li><a routerLink="/">Licencing</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Address</h3>

                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+4498886660000">+449 888 666 0000</a>
                            </li>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+5498586560350">+549 858 656 0350</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:hello@levi.com">hello@levi.com</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:info@levi.com">info@levi.com</a>
                            </li>
                            <li>
                                <i class='bx bx-current-location' ></i>
                                855 road, broklyn street new york 600
                            </li>
                            <li>
                                <i class='bx bx-planet' ></i>
                                Levi is not acting behalf the Federal Government
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <ul>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/paypal.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/skrill.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card2.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa2.jpg" alt="Method"></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>Copyright @2021 Levi. All Right Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>