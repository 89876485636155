import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MortgageInfoDialogComponent} from './mortgage-info-dialog/mortgage-info-dialog.component';

@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss']
})
export class HomeFiveComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }
  mortgages = {
      firstTime: 'You will be classified as a first time buyer if you are purchasing the first residential property in the UK. It is the one of the most exciting steps of your life, which also comes with a little bit of uncertainty towards the future financial commitment. We will be able to walk with you in every process till you get your keys for the house you love.'.substring(0, 170),
      remortgage: 'Re-mortgaging is the act of moving your existing mortgage to a new agreement, either with your current lender or with a different provider. You\'re not moving, and the new mortgage is still secured by the same property.'.substring(0, 170),
      movingHome: 'Moving home can be stressful. It is crucial to check if the mortgage you currently have will allow you to re-mortgage or port your mortgage. If your new home is within the similar value you might be able to port the existing mortgage product you currently have.'.substring(0, 170),
      btl: 'A buy-to-let mortgage is a loan that\'s specifically designed for landlords who rent out a property. You won\'t be able to finance a property purchase as an investment with a traditional residential mortgage. You\'ll need a specialist buy-to-let mortgage instead.'.substring(0, 170),
      selfEmployed: 'Are you self-employed and looking for a mortgage? You are likely to have encountered a few more roadblocks than those seeking mortgages through "traditional" employment.'.substring(0, 170),
      badCredit: 'Many people believe that because they have a bad credit history, they will be unable to apply for a mortgage. While it\'s true that some lenders prefer customers with perfect credit, the concept that having bad credit automatically disqualifies you from getting a mortgage is a big misconception.'.substring(0, 170),
      ltd: 'Investing in buy-to-let properties has been on the rise for a number of years, and recent tax changes have prompted many to consider forming a limited company as an acceptable business structure.'.substring(0, 140),
  };

  showModal(num: number) {
      this.dialog.open(MortgageInfoDialogComponent, {
          data: num,
      });
  }

}
