<div>
    <div *ngIf="data === 1">
        <p>You will be classified as a first time buyer if you are purchasing the first residential property in the UK. It is the one of the most exciting steps of your life, which also comes with a little bit of uncertainty towards the future financial commitment. We will be able to walk with you in every process till you get your keys for the house you love.</p>
        <p>How much can I afford to pay for my first home? The amount you can afford to pay for your first house or flat will generally depend on a range of factors, such as:</p>
        <ul>
            <li>The size of your deposit</li>
            <li>How much you can borrow from a bank or building society</li>
            <li>Whether you need to budget for making home improvements</li>
        </ul>
        <p>You can use our handy mortgage calculators to find out how much you could borrow and to work out how much a mortgage will cost you each month.</p>
    </div>
    <div *ngIf="data === 2">
        <p>What is re-mortgage?</p>
        <p>Re-mortgaging is the act of moving your existing mortgage to a new agreement, either with your current lender or with a different provider. You're not moving, and the new mortgage is still secured by the same property.</p>
        <p>Re-mortgages can be used for various reasons. Most people, however, simply switch mortgages because it is cheaper for them. For example, Your current lender's promotional discounted interest rate may have ended; as a result, you may be able to receive a new discount rate, or a lower APR, with another lender. Another example would be if you needed to re-mortgage to consolidate debts.</p>
        <p>It's worth noting that re-mortgaging isn't always the best option. Even if the lender you're considering switching to has a lower APR, you must consider the following factors:</p>
        <ul>
            <li>Even if you have already paid for valuation and solicitors fees with your current lender, the new lender may charge you for them.</li>
            <li>When switching mortgages, keep in mind the total repayment period. You might be able to pay less each month, but make sure to check the mortgage's final repayment date.</li>
            <li>If you re-mortgage, you may have to pay an early repayment charge to your current lender.</li>
        </ul>
        <p>THINK CAREFULLY BEFORE SECURING OTHER DEBTS AGAINST YOUR HOME.  YOUR HOME MAY BE REPOSSESSED IF YOU DO NOT KEEP UP REPAYMENTS ON A MORTGAGE OR ANY OTHER DEBT SECURED ON IT. </p>
    </div>
    <div *ngIf="data === 3">
        <p>Moving home can be stressful. It is crucial to check if the mortgage you currently have will allow you to re-mortgage or port your mortgage. If your new home is within the similar value you might be able to port the existing mortgage product you currently have. If you need additional borrowing you will have to pick a different mortgage to cover the additional borrowing, you may end up with two mortgages.</p>
        <p>We provide independent mortgage advice. This means that we advise on the entire range of products available in the mortgage market. We will review your current mortgage and also advice on other options that suit your needs.</p>
    </div>
    <div *ngIf="data === 4">
        <p>A buy-to-let mortgage is a loan that's specifically designed for landlords who rent out a property. You won't be able to finance a property purchase as an investment with a traditional residential mortgage. You'll need a specialist buy-to-let mortgage instead. </p>
        <p>When determining how much you can borrow with a buy-to-let mortgage, the lender looks at the potential rental income of the property rather than your salary.</p>
        <p><b>What is the buy to let lending criteria for most lenders?</b></p>
        <p>Although some lenders will lend to you if you only have a 20% deposit, you will generally need a deposit of at least 25% of the home's value. You must also meet the following buy-to-let mortgage requirements:</p>
        <ul>
            <li>Ideally, being a homeowner.</li>
            <li>By the age of 75, you should be able to pay off your buy-to-let mortgage.</li>
            <li>Have a good credit history.</li>
            <li>Demonstrate that you can afford to maintain and repair the BTL.</li>
        </ul>
        <p>When looking for a buy to let, it is a good idea to seek advice from local letting agents to determine what types of properties are in demand and which areas of town are best or most desired. They can tell you if the town has a university and if students are looking for a place to live.</p>
        <p><b>What types of buy-to-let mortgages are available?</b></p>
        <br>
        <p><i>Interest Only BTL mortgage</i></p>
        <p>The majority of buy-to-let mortgages are interest-only, meaning your monthly payments only cover the interest owed. This means you won't be able to repay the money you borrowed - the capital - until you sell the house (or find another way of settling the debt).</p>
        <p><i>Repayment buy to let mortgage</i></p>
        <p>By the end of the term, a repayment mortgage will have paid off the entire amount borrowed. This means that at the end of the mortgage's term, you have two options:</p>
        <p>Repayment mortgages are more expensive per month and should only be used if you can charge enough rent to cover the additional expense.</p>
        <br>
        <p><b>NOTICE: The Financial Conduct Authority does not regulate most Buy to Let Mortgages.</b></p>
    </div>
    <div *ngIf="data === 5">
        <p>Are you self-employed and looking for a mortgage? You are likely to have encountered a few more roadblocks than those seeking mortgages through "traditional" employment. </p>
        <p>Lenders need proof that payments are affordable - not just now, but also in the future - before they will consider offering anyone a mortgage. Because self-employed people's incomes fluctuate, lenders often view them as more complicated and risky. As a result, self-employed people have a lower chance of getting a mortgage than people who are employed on a PAYE basis.</p>
        <p>However, many mortgage lenders, including high-street banks, will consider applications from self-employed people. So don't be discouraged.</p>
        <p><b>What does a lender consider to be self-employed?</b></p>
        <ul>
            <li>Lenders consider you self-employed if you own more than 20% of the company that generates your main source of income.</li>
            <li>You could be a sole trader, a partner in a partnership, or a limited company director.</li>
            <li>Have a good credit history.</li>
            <li>Self-employed people include freelancers and independent contractors who work for a variety of companies.</li>
        </ul>

    </div>
    <div *ngIf="data === 6">
        <p>Many people believe that because they have a bad credit history, they will be unable to apply for a mortgage. While it's true that some lenders prefer customers with perfect credit, the concept that having bad credit automatically disqualifies you from getting a mortgage is a big misconception.</p>
        <p>A bad credit mortgage is for borrowers who have bad credit, a low credit rating, or a poor credit score. Bad credit applicants can get loans from specialised lenders, though the rates and payments may be higher than for customers with good credit. It may be possible to find a competitive deal if you have sufficient income or a substantial deposit.</p>
        <p>Lenders that provide specialist financial products like this tend to be more flexible in their lending, with decisions depending on the age, severity, and source of the credit issue in question, as well as the likelihood of recurrence.</p>
    </div>
    <div *ngIf="data === 7">
        <p>Investing in buy-to-let properties has been on the rise for a number of years, and recent tax changes have prompted many to consider forming a limited company as an acceptable business structure.</p>
        <p>Investors choose limited companies because they benefit from a lower rate of corporation tax compared to income tax on earnings made from rental income for higher tax payers.</p>
        <p><b>New Special Purpose Vehicles (SPVs)</b></p>
        <p>We receive a substantial number of limited company buy to let mortgage applications from individuals who have recently established an SPV limited company. One of the advantages of forming an SPV is that you can put it up and begin investing the same day. However, because the business is new, there will be no income to use as collateral on a buy-to-let mortgage application. Fortunately, this is not a problem because the lender will focus on the limited company's Directors and Shareholders when underwriting the application. Treating your application as though it were a personal investment, with your income being used to meet affordability criteria and underwrite the mortgage.</p>
        <p><b>Established Limited Companies</b></p>
       <p>Lenders will ask for two years of accounts in order to underwrite your buy to let mortgage application for more established SPVs, in addition to looking at the Directors and Shareholder finances, but the criteria on income are the same as with a freshly formed limited company.</p>
    </div>
</div>
