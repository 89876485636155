import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeFiveComponent } from './components/home-five/home-five.component';

const routes: Routes = [
    // {path: 'app', component: HomeFiveComponent, canActivate: [AuthGuard]},
    {path: '', component: HomeFiveComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
