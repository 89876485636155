<div class="tab-content" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <form>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Purchase price" [(ngModel)]="details.price"  [ngModelOptions]="{standalone: true}">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Interest Rate" [(ngModel)]="details.interestRate" [ngModelOptions]="{standalone: true}" >
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Down payment" [(ngModel)]="details.downPayment" [ngModelOptions]="{standalone: true}" >
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Mortgage Term" [(ngModel)]="details.term" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>

                <div>
                    <button type="submit" class="btn banner-form-btn" (click)="calculateMortgage()">Calculate</button>
                    <span style="font-size: 15px; text-align: center"><b>Your home may be repossessed if you do not keep up repayments on your mortgage.</b></span>

                </div>
                <div style="margin-top: 25px">
                    <span *ngIf="repayment">Estimated Monthly Repayment: £{{repayment}}</span>
                    <span style="font-size: 12px">This information is a guide only and should not be relied on as a recommendation or advice, that any particular mortgage is suitable for you. All mortgages are subject to the applicant(s) meeting the eligibility criteria of the specific lender. You should make an appointment to receive mortgage advice which will based on your needs and circumstances.</span>
                </div>
            </div>
        </form>
    </div>
</div>
